import axios from "axios";
import msg from "../../util/msg.js";

export function list(param) {
  return axios.get("/user/list", { params: param });
}

export function oprAmount(id, oprData) {
  if (oprData.fundType == null) {
    msg.fail("请选择 添加/扣除 操作");
    return;
  }

  if (
    oprData.limitAmount == null ||
    oprData.limitAmount < 0 ||
    oprData.freeAmount == null ||
    oprData.freeAmount < 0
  ) {
    msg.fail("操作金额应 >= 0");
    return;
  }
  oprData.userId = id;
  axios.post("/user/oprAmount", oprData).then((data) => {
    msg.success();
  });
}
export function oprProxy(param) {
  axios.post("/user/oprProxy", param).then((data) => {
    msg.success();
  });

}