<template>
  <div id="user-list">

    <el-form :inline="true" :model="form" class="demo-form-inline">
      <el-form-item label="ID">
        <el-input v-model="form.id" placeholder="ID"></el-input>
      </el-form-item>
      <el-form-item label="邮箱">
        <el-input v-model="form.email" placeholder="邮箱"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="loadList">查询</el-button>
      </el-form-item>
    </el-form>

    <el-table :data="page.list" border>
      <el-table-column prop="id" label="ID" width="50">
      </el-table-column>
      <el-table-column prop="email" label="邮箱">
      </el-table-column>
      <el-table-column label="余额">
        <template v-slot="row">
          <el-popover placement="left" width="200" trigger="hover">
            <el-descriptions class="margin-top" column="1" border>
              <el-descriptions-item label="可提">
                {{ row.row.freeAmount }}
              </el-descriptions-item>
              <el-descriptions-item label="不可提">
                {{ row.row.limitAmount }}
              </el-descriptions-item>
            </el-descriptions>
            <span slot="reference">{{ row.row.totalAmount }}</span>
          </el-popover>
        </template>
      </el-table-column>
      <el-table-column prop="isProxy" label="代理" width="80">
        <template v-slot="row">
          <span>{{ row.row.proxy ? '是' : '否' }}</span><span v-if="row.row.proxy===true">({{row.row.rate}})</span>
        </template>
      </el-table-column>
      <el-table-column label="操作">
        <template v-slot="row">
          <el-popover placement="left" trigger="click" width="400">
            <el-form>
              <el-form-item>
                <el-radio v-model="oprAmountForm.fundType" label="SYS_IN">增加</el-radio>
                <el-radio v-model="oprAmountForm.fundType" label="SYS_OUT">扣除</el-radio>
              </el-form-item>
              <el-form-item label="不提款">
                <el-input type="number" v-model="oprAmountForm.limitAmount"></el-input>
              </el-form-item>
              <el-form-item label="可提款">
                <el-input type="number" v-model="oprAmountForm.freeAmount"></el-input>
              </el-form-item>
              <el-form-item>
                <el-button @click="oprAmount(row.row.id, oprAmountForm)">提交</el-button>
              </el-form-item>
            </el-form>
            <el-button slot="reference">调整余额</el-button>
          </el-popover>

          <el-divider direction="vertical"></el-divider>
          <el-button @click="goBI(row.row.id)">查看投注</el-button>
          <el-divider direction="vertical"></el-divider>
          <el-button @click="goFR(row.row.id)">资金记录</el-button>
          <el-divider direction="vertical"></el-divider>
          <el-popover placement="left" trigger="click" width="400" popper-class="custom-popover">
            <el-form>
              <el-form-item label="代理返点">
                <el-input v-model="oprProxyForm.rate"></el-input>
              </el-form-item>
              <el-form-item>
                <el-button
                    type="primary"
                    size="mini"
                    @click="oprProxy(row.row.id, true)">设为代理并保存返点
                </el-button>
                <el-button
                    v-if="row.row.proxy"
                    type="danger"
                    size="mini"
                    @click="oprProxy(row.row.id, false)">撤销代理资格
                </el-button>
              </el-form-item>
              <el-divider></el-divider>
              <el-form-item label="上线代理ID">
                <el-input v-model="oprProxyForm.parentId"></el-input>
              </el-form-item>
              <el-form-item>
                <el-button @click="oprProxy(row.row.id, 'relation')">设为代理【{{ oprProxyForm.parentId }}】的下线</el-button>
              </el-form-item>
            </el-form>
            <el-button slot="reference" @click="setProxyOldVal(row.row.rate,row.row.parentId)">代理设置</el-button>
          </el-popover>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination style="text-align: center" :page-count="page.totalPage" :current-page="form.pageNo"
                   layout="prev, pager, next" @current-change="changePage">
    </el-pagination>
  </div>
</template>
<script>
import {list, oprAmount, oprProxy} from './user'
import msg from "@/util/msg";

export default {
  name: "UserList",
  data() {
    return {
      form: {
        pageNo: 1,
        pageSize: 20,
        id: null,
        email: null,
      },

      oprAmountForm: {
        fundType: 'SYS_IN',
        limitAmount: 0,
        freeAmount: 0,
        userId: null,
      },

      oprProxyForm:{
        userId: 0,
        parentId: 0,
        rate: 0,
        oprType: null,
      },

      page: {
        list: null,
        totalPage: null,
      },
    };
  },

  methods: {
    oprAmount,

    loadList() {
      list(this.form).then((data) => {
        this.page = data;
      });
    },

    changePage(val) {
      this.form.pageNo = val;
      this.loadList();
    },

    goBI(userId) {
      this.$router.push({
        path: '/bi-list?userId=' + userId,
      });
    },

    goFR(userId) {
      this.$router.push({
        path: '/fr-list?userId=' + userId,
      });
    },

    oprProxy(id, oprType) {
      this.oprProxyForm.userId=id;
      this.oprProxyForm.oprType=oprType;
      oprProxy(this.oprProxyForm);
    },

    setProxyOldVal(rate,parentId){
      this.oprProxyForm.rate = rate;
      this.oprProxyForm.parentId = parentId;
    }

  },

  created() {
    this.loadList();
  },

};
</script>

<style>
.custom-popover.el-popover {
  background: #f5f7fa !important;
  border-color: #e4e7ed;
}

.custom-popover {
  position: relative;
  background: #f5f7fa;
  border-radius: 4px;
  box-shadow: 0 2px 12px 0 rgba(0,0,0,.1);
}

.custom-popover[x-placement^=left] .popper__arrow {
  border-left-color: #f5f7fa !important;
}

.custom-popover .popover-content {
  color: #606266;
}
</style>